$(document).ready(function(){
  $('.dropbtn.uc-link').click(function(){
    var aindex = $(this)[0].dataset.dtarget;
    $('.uc-dropdown_list').each(function(k, v){
      if(v.dataset.dropdown != aindex) {
        this.classList.remove("show");
      }
    })
  })

  $('.uc-btn.uc-btn_frames').click(function(){
    var x = this.closest('.carousel');
    var y = x.querySelector('[data-current=true]').dataset.index;
    x.querySelector('.current-slide-index').innerHTML = parseInt(y, 10) + 1;
  })

  jQuery.each(jQuery('[data-role="video-player"]'), function () {
    var $this_video = $(this);
    var video = this;
    var $deployer = $($this_video.siblings()).find('[data-role="video-player-deployer"]');

    $deployer.on("click", function () {
      var $video_prev = $this_video.siblings('[data-role="video-prev"]');

      if (video.paused == true) {
        video.play();
        $deployer.addClass('playing');
        $this_video.attr('controls', 'true');
        $video_prev.addClass('hide').removeClass('paused');
      } else {
        video.pause();
        $video_prev.addClass('paused').removeClass('hide').removeAttr('style');
        $deployer.removeClass('playing');
        $this_video.removeAttr('controls');
      }
    });

    if (video.paused == true) {
      $deployer.addClass('check-playing');
    } else {
      $deployer.removeClass('check-playing');
    }
  });

  /** Inicializacion de datepicker, en español y con fechas con eventos marcadas */
  jQuery.each(jQuery('.calendar-box'), function () {
    $.post(jQuery('[data-role="hidden-surl-ajax"]').val()+"/wp-json/obtener/eventos", {}, function(data){
      var eventDates = {};
      jQuery.each(data, function (key, value) {
        eventDates[ new Date( value )] = new Date( value );
      });
      $('#datepicker').datepicker($.datepicker.regional['es'] = {
        closeText: 'Cerrar',
        prevText: '<Ant',
        nextText: 'Sig>',
        currentText: 'Hoy',
        monthNames: ['Enero', 'Febrero', 'Marzo', 'Abril', 'Mayo', 'Junio', 'Julio', 'Agosto', 'Septiembre', 'Octubre', 'Noviembre', 'Diciembre'],
        monthNamesShort: ['Ene', 'Feb', 'Mar', 'Abr', 'May', 'Jun', 'Jul', 'Ago', 'Sep', 'Oct', 'Nov', 'Dic'],
        dayNames: ['Domingo', 'Lunes', 'Martes', 'Miércoles', 'Jueves', 'Viernes', 'Sábado'],
        dayNamesShort: ['Dom', 'Lun', 'Mar', 'Mié', 'Juv', 'Vie', 'Sáb'],
        dayNamesMin: ['Do', 'Lu', 'Ma', 'Mi', 'Ju', 'Vi', 'Sá'],
        weekHeader: 'Sm',
        dateFormat: 'dd/mm/yy',
        firstDay: 1,
        isRTL: false,
        showMonthAfterYear: false,
        yearSuffix: '',
        beforeShowDay: function(date) {
          var highlight = eventDates[date];
          if( highlight ) {
            return [true, 'ui-datepicker-event-day', 'Fecha con evento asociado'];
          } else {
            return [false, '', ''];
          }
        }
      });
    });

    $("#datepicker").on("change",function(){
      var selected = $(this).val();
      $.post(jQuery('[data-role="hidden-surl-ajax"]').val()+"/wp-json/buscar/fecha", { selected_date: selected }, function(data){
        if(data['status'] == -1){ window.location.href = '/bad-request/'; }
        $('[data-role="container-eventos-futuros"]').html(data['eventos_futuros']);
        $('[data-role="container-eventos-pasados"]').html(data['eventos_pasados']);
      });
    });
  });

  function formatBytes(a,b){if(0==a)return"0 Bytes";var c=1024,d=b||2,e=["Bytes","KB","MB","GB","TB","PB","EB","ZB","YB"],f=Math.floor(Math.log(a)/Math.log(c));return parseFloat((a/Math.pow(c,f)).toFixed(d))+" "+e[f]}

  jQuery.each(jQuery('.wp-block-file a'), function () {
    var url = $(this);
    var request;
    request = $.ajax({
      type: "HEAD",
      url: url[0].href,
      success: function () {
        // console.log("Size is " + formatBytes(request.getResponseHeader("Content-Length"), 3));
        url.append("<span class='uc-subtitle' style='display: block; text-decoration: underline; text-decoration-color: white;'>"+ formatBytes(request.getResponseHeader("Content-Length"), 3) +"</span>");
      }
    });
  });

  jQuery.each(jQuery('[data-role="search-button"]'), function (e) {
    var $this = $(this);
    $this.click(function(){
      $this.toggle();
      $this.siblings('[data-role="search-button"]').toggle();
      $this.siblings('[data-role="search-input"]').toggle();
    });
  });

  jQuery.each(jQuery('[data-role="button-filtrar-tecnologias"]'), function (e) {
    var $this = $(this);
    $this.click(function(){
      var search, filters = [], type, term, lang;
      jQuery.each(jQuery('[data-role="search-input-ajax"]'), function (e) {
        search = $(this).val();
      });
      jQuery.each(jQuery('[data-role="filters-checkbox-ajax"]'), function (e) {
        if($(this).is(":checked")){
          filters.push($(this).val());
        }
      });
      jQuery.each(jQuery('[data-role="hidden-type-ajax"]'), function (e) {
        type = $(this).val();
      });
      jQuery.each(jQuery('[data-role="hidden-term-ajax"]'), function (e) {
        term = $(this).val();
      });
      jQuery.each(jQuery('[data-role="hidden-lang-ajax"]'), function (e) {
        lang = $(this).val();
      });
      $.post(jQuery('[data-role="hidden-surl-ajax"]').val()+"/wp-json/filtrar/tecnologias",
       {
         search:  search,
         filters: filters,
         type:    type,
         term:    term,
         lang:    lang
       },
       function(data, status){
         if(data['status'] == -1){ window.location.href = '/bad-request/'; }
         $('[data-role="container-results-tecnologias"]').html(data);
       });
    });
  });

  jQuery.each(jQuery('[data-role="button-filtrar-eventos"]'), function (e) {
    var $this = $(this);
    $this.click(function(){
      var search, filters;
      jQuery.each(jQuery('[data-role="search-input-ajax"]'), function (e) {
        search = $(this).val();
      });
      jQuery.each(jQuery('[data-role="filters-checkbox-ajax"]'), function (e) {
        if($(this).is(":checked")){
          filters = $(this).val();
        }
      });
      $.post(jQuery('[data-role="hidden-surl-ajax"]').val()+"/wp-json/filtrar/eventos",
       {
         search: search,
         filters: filters
       },
       function(data){
         if(data['status'] == -1){ window.location.href = '/bad-request/'; }
         $('[data-role="container-eventos-futuros"]').html(data['eventos_futuros']);
         $('[data-role="container-eventos-pasados"]').html(data['eventos_pasados']);
       });
    });
  });

  jQuery.each(jQuery('[data-role="collapse-header"]'), function (e) {
		var $this = $(this);
		var $this_icon = $($this.find('.uc-icon'));

		$(window).scroll(function(){
			var scroll = $(window).scrollTop();

			console.log($('header').height());
			if(scroll >= 120){
				$('header').addClass('sticky');
			}else{
				$('header').removeClass('sticky');
			}
		});

		$this.on('click', function(){
			$('body').toggleClass('nav-open');
			$($this.data('target')).toggleClass('active');
			$this.toggleClass('active');

			console.log($(window).width());
			if ($this_icon.html() === "menu") {
        $this_icon.html('close');
        // if($(window).width() <= 768){
        //   $($this.data('target')).attr('style', 'height: calc(100vh - '+$('header').height()+'px)');
        // }else{
        $($this.data('target')).attr('style', 'height: auto');
        // }
			} else {
				console.log('open');
				$this_icon.html('menu');
				$($this.data('target')).attr('style', 'auto');
			}
		});
	});

	jQuery.each(jQuery('[data-role="open-submenu"]'), function (e) {
		var $this = $(this);

		$this.on('click', function(){
			$('body').toggleClass('nav-open');
			$this.toggleClass('active');

			if ($this.html() === "arrow_drop_down") {
				console.log('closed');
				$this.html('arrow_drop_up');
			} else {
				console.log('open');
				$this.html('arrow_drop_down')
			}
		});
	});

  jQuery.each(jQuery('img[data-srcset]'), function () {
    var $this_img = $(this);
    var src_set = $this_img.data('srcset') || '';

    if(src_set !== ''){
      var sizes = src_set.split(',');
      var resize = [];
      var default_src = $this_img.attr('src');

      if(sizes.length > 0){
        $this_img.attr('data-srcfull', default_src);
        sizes.forEach(function (value, key) {
          value = value.slice(0,-1);
          var size = value.split(' ');

          resize.push({ size: size[1], src: size[0] })
        });
        resize.reverse();
        resize.forEach(function (fuente, key) {
          var window_width = $(window).width();
          if(window_width <= fuente['size']){ $this_img.attr('src', fuente['src']) }
          if(window_width > resize[0]['size']){ $this_img.attr('src', default_src) }
        });
        $(window).on('resize', function () {
          resize.forEach(function (fuente, key) {
            var window_width = $(window).width();
            if(window_width <= fuente['size']){ $this_img.attr('src', fuente['src']) }
            if(window_width > resize[0]['size']){ $this_img.attr('src', default_src) }
          });
        });
      }
    }
  });
})
